import React from "react";
import {Button, Paper} from "@mui/material";
import "./PaymentForm.css"
import {Navigate} from "react-router-dom";
import {paysafeService} from "./PaysafeService";
import {orderService} from "../../../network/OrderService";

interface PaymentFormProps {}
interface PaymentFormState {
    isComplete: boolean
    isSubmitted: boolean
}
export class PaymentForm extends React.Component<PaymentFormProps, PaymentFormState> {

    state = {
        isComplete: false,
        isSubmitted: false,
    }

    componentDidMount() {
        paysafeService.init()
    }


    submitPayment = () => {

        // const userBillingData = userDataService.getUserBilling()
        // if (!userBillingData) {
        //     console.log("User data was null! Throw an error here!!")
        //     return
        // }
        //
        // const cardNumber = this.state.cardNumber
        // const splitExpiry = this.state.expiryDate.split("/")
        // const expiryMonth = +splitExpiry[0]
        // const expiryYear = +splitExpiry[1]
        //
        // const paymentParams = {
        //     cardNumber: cardNumber,
        //     cardholderName: this.state.cardholderName,
        //     expiryMonth: expiryMonth,
        //     expiryYear: expiryYear,
        //     address1: userBillingData.address1,
        //     address2: userBillingData.address2,
        //     city: userBillingData.city,
        //     province: userBillingData.province,
        //     postalCode: userBillingData.postalCode,
        //     country: userBillingData.country,
        // }
        //
        //
        // paysafeService.createToken(paymentParams)
        //     .then(response => {
        //         console.log(response)
        //     })


        this.setState({
            isSubmitted: true
        })

        paysafeService.getToken().then(token => {
            orderService.createOrder(token)
                .then(order => {
                    console.log(order)
                    this.setState({
                        isComplete: true
                    })
                })
        })
    }

    render() {
        return <div className="payment-form">

            {this.state.isComplete ? <Navigate to="/order-complete" /> : null }

            <div className="payment-form-horizontal">
                <div className="payment-form-field">
                    <Paper id="cardNumber" className="paysafe-js" ></Paper>
                </div>
            </div>

            <div className="payment-form-horizontal">
                <div className="payment-form-field">
                    <Paper id="expiryDate" className="paysafe-js"></Paper>
                </div>
                <div className="payment-form-field">
                    <Paper id="cvv" className="paysafe-js"></Paper>
                </div>
            </div>

            <div className="payment-form-button">
                <Button id="payNow" variant="contained" onClick={this.submitPayment} disabled={this.state.isSubmitted}>Pay now</Button>
            </div>
        </div>
    }
}