import {userDataService} from "../component/app/address/UserDataService";
import {CartCreateRequest, CartModel, CartResponse, CartUpdateRequest} from "./CartNetwork";
import axios from "axios";

const CART_BASE_PATH = "/cart"
const CART_GET_PATH = "/"
const CART_CREATE_PATH = "/create"
const CART_UPDATE_PATH = "/update"
const CART_ADDRESS_UPDATE_PATH = "/update-address"

const CART_BASE_URL = process.env.REACT_APP_TICKET_SERVER_BASE_URL + CART_BASE_PATH

export class TicketService {

    createCart(ticketQuantity: number) : Promise<CartModel> {
        const url = CART_BASE_URL + CART_CREATE_PATH
        const data = {
            ticketQuantity: ticketQuantity
        }

        return axios.post<CartCreateRequest, CartResponse>(url, data)
            .then(response => {
                return response.data.data
            })
    }

    updateCart(ticketQuantity: number) : Promise<CartModel> {
        const url = CART_BASE_URL + CART_UPDATE_PATH

        const userData = userDataService.getUserData()

        const data = {
            cartId: userDataService.getCartId(),
            ticketQuantity: ticketQuantity,
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            email: userData?.email, // TODO add these. Maybe rename it from "billing" to something else
            phone: userData?.phone,
            billingAddress: {
                line1: userData?.address1,
                line2: userData?.address2,
                city: userData?.city,
                province: userData?.province,
                postalCode: userData?.postalCode,
                country: userData?.country,
            },
        }

        return axios.post<CartUpdateRequest, CartResponse>(url, data)
            .then(response => {
                return response.data.data
            })
    }

    updateCartAddress() : Promise<CartModel> {
        const url = CART_BASE_URL + CART_ADDRESS_UPDATE_PATH

        const userData = userDataService.getUserData()

        const data = {
            cartId: userDataService.getCartId(),
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            email: userData?.email, // TODO add these. Maybe rename it from "billing" to something else
            phone: userData?.phone,
            billingAddress: {
                line1: userData?.address1,
                line2: userData?.address2,
                city: userData?.city,
                province: userData?.province,
                postalCode: userData?.postalCode,
                country: userData?.country,
            },
        }

        return axios.post<CartUpdateRequest, CartResponse>(url, data)
            .then(response => {
                return response.data.data
            })
    }

    getCartId(): string | null {
        return userDataService.getCartId()
    }

    fetchCart(): Promise<CartModel> {
        const cartId = this.getCartId()
        if (cartId == null) {
            return Promise.reject()
        }

        const url = CART_BASE_URL + CART_GET_PATH + cartId
        return axios.get<CartUpdateRequest, CartResponse>(url)
            .then(response => {
                return response.data.data
            })
    }
}
export const ticketService: TicketService = new TicketService();