import React from "react";
import {Divider, Link} from "@mui/material";

export class AppMenu extends React.Component<any, any> {

    linkStyle = {
        color: '#222222'
    }

    // TODO: Remove this hack after the fest.
    shouldShowTicketLink = (): boolean => {
        const cutoff = new Date(Date.UTC(2023, 1, 8, 17, 0, 0, 0))
        const now = new Date()
        return now < cutoff
    }

    render() {
        return <div className="app-menu">
            {this.shouldShowTicketLink() ? <Link href="/tickets" sx={this.linkStyle} underline="none">TICKETS</Link > : null}

            <Link href="/" sx={this.linkStyle} underline="none">TERMS AND CONDITIONS</Link >
        </div>
    }
}