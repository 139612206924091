import React from "react";
import {AddressForm} from "./AddressForm";
import {CartSummary} from "../order/CartSummary";

export class AddressPage extends React.Component {

    render() {
        return <div className="address-page">
            <h1>Billing Address</h1>
            <div className="address-outer-content">
                <div className="address-horizontal-section">
                    <AddressForm />
                    <CartSummary />
                </div>
            </div>
        </div>
    }
}
