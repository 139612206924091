import React from "react";
import {PrizeGrid} from "./PrizeGrid";
import "./PrizePage.css"

interface TermsProps {

}
export class TermsContent extends React.Component<any, any> {
    render() {
        return <div className="terms-content">
                <h2>Raffle License #751743</h2>

                <h3>1. Eligibility</h3>
                <div className="terms-section">
                    All residents of Ontario, 18 years of age and older, who are present in Ontario at the time of
                    ticket purchase are eligible to win. The raffle tickets are only being solicited, promoted,
                    advertised, and marketed to persons in Ontario.
                    Raffle tickets shall not be sold to a person under 18 years of age, this raffle is not directed at or
                    targeting anyone under 18 years of age. Adult ticket buyers may not enter the name of a person
                    under 18 years of age on the ticket.
                    Designated organizers and volunteers-in-charge (core organizing volunteers, including
                    SOIceFest directors) and prize donors are prohibited from purchasing tickets.
                </div>
                <h3>2. Ticket Sales</h3>
                <div className="terms-section">
                    Tickets will be sold until 12PM EST Wednesday February 8th, 2023. Tickets will be available online and sold at a rate of:
                </div>
                <ul>
                    <li>$5 for 1 ticket</li>
                    <li>$10 for 3 tickets</li>
                    <li>$25 for 8 tickets</li>
                </ul>
                <div className="terms-section">
                    Tickets will only be sold in the Province of Ontario. Raffle tickets will be e-mailed, or otherwise electronically sent, to purchasers before the draw takes place. Tickets purchased are not eligible for a refund.
                </div>
                <h3>3. Time and Location of Draw</h3>

                <div className="terms-section">
                    The draw will take place at Army Navy + Air Force Unit 378, Maynooth, Ontario on February 11, 2023 from approximately 8:00 PM EST to 10:00 PM EST. At least 2 executive members of SOIceFest will be present to witness the draw.
                </div>
                <div className="terms-section">
                    The draw will be livestreamed on Zoom at the following link: <a href="https://us04web.zoom.us/j/71902833210?pwd=SPKlwgWdHjHFJfmJ2qqbbbtB7WjBcC.1">https://us04web.zoom.us/j/71902833210?pwd=SPKlwgWdHjHFJfmJ2qqbbbtB7WjBcC.1</a>
                </div>
                <h3>4. Selection of a Winner</h3>
                <div className="terms-section">
                    The winner will be selected by drawing a single ticket from the eligible tickets purchased.
                    Tickets will be randomly drawn out of an appropriate
                    container. The draw will be conducted in accordance with the Alcohol and Gaming Commission
                    of Ontario (AGCO), and the <span><a href="https://www.ontario.ca/laws/statute/92g24/">Ontario Gaming Control Act, 1992</a></span> and its <a href="https://www.ontario.ca/laws/regulation/120078/">Regulations</a>, specifically
                    Chapter 5: Raffles available at the following link <a href="https://www.agco.ca/lottery-and-gaming/chapter-5-raffles">Chapter 5: Raffles | Alcohol and Gaming
                    Commission of Ontario (agco.ca)</a>.
                    SOIceFest will make every reasonable effort to contact the winner, including by phone, email,
                    and social media. List of winners will be published on <a href="https://soicefestraffle.ca/">www.SOIceFestRaffle.com</a>. The winner
                    must collect within 6 months of the raffle draw date, after which the prize will become the
                    property of SOIceFest.
                    In the event that more than one name is displayed on the winning ticket stub, the prize will be
                    awarded to only one of the individuals named on the ticket. Neither SOIcefest nor the AGCO is
                    responsible for any disputes among the individuals whose names are written on the ticket stub.
                </div>
                <h3>5. Prize Structure</h3>
                <div className="terms-section">
                    There will be one draw for each of the following numbered prizes/prize-packages. Draw for
                    numbered prizes is in descending order culminating in #1, the Grand Prize. Winning ticket will
                    not be returned to the draw to be eligible for other prizes.
                </div>
                <PrizeGrid/>
                <h3>6. Number of Total Tickets</h3>
                <div className="terms-section">
                    There are only 2000 tickets available.
                </div>
                <h3>7. Contact</h3>
                <div className="terms-section">
                    In the event of a complaint or dispute, please contact:
                    The Southern Ontario Ice Climbing Festival Inc.
                    <a href="mailto://soicefest@gmail.com">soicefest@gmail.com</a>
                    <div className="terms-subject-line">Subject Line: ATTN: Directors; RE: Raffle dispute</div>
                </div>
            </div>
    }
}