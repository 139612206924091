import React from "react";
import "./OrderPage.css"

interface LineItemProps {
    packType: number,
    quantity: number,
    price: number
}
export class LineItem extends React.Component<LineItemProps, any> {
    currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
    });

    formatPrice = (): string => {
        return this.currencyFormatter.format(this.props.price / 100)
    }

    render() {
        return (
            <div key={this.props.packType + "-pack"} className="order-line-item-container">
                <div className="order-line-item-text">{this.props.packType}-pack&nbsp;x{this.props.quantity}</div>
                <div className="order-line-item-value">{this.formatPrice()}</div>
            </div>
        );
    }
}