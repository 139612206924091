import React from "react";
import "./OrderPage.css"
import {orderService} from "../../../network/OrderService";
import {OrderModel} from "../../../network/OrderNetwork";
import {OrderCompleteContent} from "./OrderCompleteContent";

interface OrderCompletePageState {
    order: OrderModel | null
}

export class OrderCompletePage extends React.Component<any, OrderCompletePageState> {

    state = {
        order: null
    }

    componentDidMount() {
        orderService.getOrder()
            .then(order => {
                this.setState({
                    order: order,
                })
            })
    }

    renderTitle(): React.ReactElement | null {
        if (this.state.order != null) {
            const order = this.state.order as OrderModel
            return <h1>Thank you {order.firstName}!</h1>
        }

        return null
    }

    renderContent(): React.ReactElement | null {
        if (this.state.order != null) {
            const order = this.state.order as OrderModel
            return <OrderCompleteContent order={this.state.order} />
        }

        return null
    }

    render() {
        return (
            <div className="order-page">
                {this.renderTitle()}
                {this.renderContent()}
            </div>
        );
    }
}