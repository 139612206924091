import React from "react";
import {ticketService} from "../../../network/TicketService";
import {CartModel} from "../../../network/CartNetwork";
import {Divider, Paper} from "@mui/material";
import "./CartSummary.css"
import {LineItem} from "./LineItem";


interface CartSummaryState {
    cart?: CartModel
}
export class CartSummary extends React.Component<any, CartSummaryState> {

    state = {
        cart: undefined
    }

    paperStyle = {
        bgcolor: '#FDFDFD'
    }

    dividerStyle = {
        color: '#888888',
        width: 1.0,
    }

    currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
    });

    componentDidMount() {
        ticketService.fetchCart()
            .then(cart => {
                this.setState({
                    cart: cart
                })
            })
    }

    renderOrderSummary(): React.ReactElement | null {
        if (!this.state.cart) {
            return null
        }
        const cart = this.state.cart as CartModel

        if (!cart.lineItems) {
            return null
        }
        const lineItems = cart.lineItems

        const formattedTotal = this.currencyFormatter.format(cart.lineItems.totalInCents / 100)

        return <div className="cart-summary-outer">
            <Paper elevation={3} sx={this.paperStyle} className="cart-summary">
                <h2>Tickets</h2>
                {this.renderLineItem(8, lineItems.eightPacks, lineItems.eightPackTotalCents)}
                {this.renderLineItem(3, lineItems.threePacks, lineItems.threePackTotalCents)}
                {this.renderLineItem(1, lineItems.onePacks, lineItems.onePackTotalCents)}
                <div className="cart-total-line-item-wrapper">
                    <Divider sx={this.dividerStyle} />
                    <div className="cart-total-line-item-container">
                        <div className="cart-total-text">Total:</div>
                        <div className="cart-line-item-value">{formattedTotal}</div>
                    </div>
                </div>
            </Paper>
        </div>
    }

    renderLineItem(packType: number, quantity: number, price: number): React.ReactElement | null {
        if (quantity === 0) {
            return null
        }

        return <LineItem packType={packType} quantity={quantity} price={price} />
    }


    render() {
        return this.renderOrderSummary()
    }
}