import React from "react";
import "./TicketPriceChart.css"
import {Paper} from "@mui/material";

export class TicketPriceChart extends React.Component<any, any> {

    paperStyle = {
        bgcolor: '#FDFDFD'
    }

    render() {
        return <Paper className="ticket-price-chart" sx={this.paperStyle}>
            <h2 className="ticket-price-title">Ticket Prices</h2>
            <div className="ticket-price-item">1 for $5.00</div>
            <div className="ticket-price-item">3 for $10.00</div>
            <div className="ticket-price-item">8 for $25.00</div>
        </Paper>
    }
}