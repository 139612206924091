import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'typeface-roboto'
import LoginPage from './component/login/LoginPage';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Root from "./component/app/Root";
import {AddressPage} from "./component/app/address/AddressPage";
import {PaymentPage} from "./component/app/payment/PaymentPage";
import {TicketPage} from "./component/app/ticket/TicketPage";
import {OrderCompletePage} from "./component/app/order/OrderCompletePage";
import AppHeader from "./component/app/AppHeader";
import {TermsPage} from "./component/app/terms/TermsPage";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <TermsPage />,
    },
    {
        path: "/tickets",
        element: <TicketPage />,
    },
    {
        path: "/contact",
        element: <AddressPage />,
    },
    {
        path: "/payment",
        element: <PaymentPage />,
    },
    {
        path: "/order-complete",
        element: <OrderCompletePage />,
    },
]);

root.render(
    <div className="root">
        <AppHeader>
            <RouterProvider router={router}/>
        </AppHeader>
    </div>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
