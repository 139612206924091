import React from "react";
import TextField from "@mui/material/TextField";
import {ticketCalculator} from "./TicketCalculator";
import "./TicketLineItems.css"
import {Divider} from "@mui/material";


interface TicketCalculatorProps {
    quantity: number
}
interface TicketCalculatorState {
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'CAD',
});

const dividerStyle = {
    color: '#888888',
    width: 1.0,

}

export class TicketLineItems extends React.Component<TicketCalculatorProps, TicketCalculatorState> {

    getBreakdown(): React.ReactElement[] {
        const breakdownUi = []
        const ticketBreakdown = ticketCalculator.calculate(this.props.quantity)
        console.log(ticketBreakdown)

        let totalCents = 0
        const eightPackLine = ticketBreakdown.eightPackLine
        if (eightPackLine && eightPackLine.quantity > 0) {
            totalCents += eightPackLine.lineTotal
            const lineItem = this.getLineItem(8, eightPackLine.quantity, eightPackLine.lineTotal)
            breakdownUi.push(lineItem)
        }

        const threePackLine = ticketBreakdown.threePackLine
        if (threePackLine && threePackLine.quantity > 0) {
            totalCents += threePackLine.lineTotal
            const lineItem = this.getLineItem(3, threePackLine.quantity, threePackLine.lineTotal)
            breakdownUi.push(lineItem)
        }

        const onePackLine = ticketBreakdown.onePackLine
        if (onePackLine && onePackLine.quantity > 0) {
            totalCents += onePackLine.lineTotal
            const lineItem = this.getLineItem(1, onePackLine.quantity, onePackLine.lineTotal)
            breakdownUi.push(lineItem)
        }

        const formattedTotal = currencyFormatter.format(totalCents / 100)
        const totalComponent = <div className="line-item-total-container-wrapper">
            <Divider sx={dividerStyle} />
            <div key={"price-total"} className="line-item-total-container">
                <div className="line-item-total">Total</div>
                <div className="line-item-total-price">{formattedTotal}</div>
            </div>
        </div>

        breakdownUi.push(totalComponent)

        return breakdownUi
    }

    getLineItem(packType: number, quantity: number, price: number): React.ReactElement {
        const formattedPrice = currencyFormatter.format(price / 100)
        return <div key={packType + "-pack"} className="line-item-container">
            <div className="line-item-quantity-container">{packType}-pack x{quantity}</div>
            <div className="line-item-price">{formattedPrice}</div>
        </div>
    }

    render() {
        return <div className="line-item-breakdown">
            {this.getBreakdown()}
        </div>;
    }

}