import React from "react";
import TextField from "@mui/material/TextField";
import "./AddressForm.css"
import {Button, FormControl} from "@mui/material";
import {userDataService} from "./UserDataService";
import {Navigate} from "react-router-dom";
import {ticketService} from "../../../network/TicketService";

interface AddressFormProps {}
interface AddressFormState {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    address1: string,
    address2: string,
    city: string,
    postalCode: string,
    isSubmitted: boolean
}

const HARDCODED_PROVINCE = "ON"
const HARDCODED_COUNTRY = "CA"
export class AddressForm extends React.Component<AddressFormProps, AddressFormState> {

    state = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        address1: "",
        address2: "",
        city: "",
        postalCode: "",
        isSubmitted: false
    }

    onTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.currentTarget.value)
        this.setState({
            ...this.state,
            [e.currentTarget.name]: e.currentTarget.value
        })
    }

    areFieldsValid = (): boolean => {
        const postalCodeRegex = new RegExp("^[a-zA-Z][0-9][a-zA-Z] ?[0-9][a-zA-Z][0-9]$");
        const isPostalCodeValid = postalCodeRegex.test(this.state.postalCode)

        if (!this.state.firstName
            || !this.state.lastName
            || !this.state.email
            || !this.state.phone
            || !this.state.address1
            || !this.state.city
            || !this.state.postalCode
            || !isPostalCodeValid) {
            return false
        }

        return true
    }

    onSubmit = () => {
        const userBillingData = {
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            phone: this.state.phone,
            address1: this.state.address1,
            address2: this.state.address2,
            city: this.state.city,
            province: HARDCODED_PROVINCE,
            postalCode: this.state.postalCode,
            country: HARDCODED_COUNTRY,
        }
        userDataService.saveUserBilling(userBillingData)
        ticketService.updateCartAddress()
            .then(cart => {
                this.setState({
                    isSubmitted: true
                })
            })
    }

    render() {
        return <div className="address-form">
            <FormControl>

                {this.state.isSubmitted ? <Navigate to="/payment" /> : null }

                <div className="address-horizontal-section">
                    <div className="address-form-field">
                        <TextField name="email" required fullWidth label="Email" onChange={this.onTextChange} />
                    </div>
                    <div className="address-form-field">
                        <TextField name="phone" required fullWidth label="Phone" onChange={this.onTextChange} />
                    </div>
                </div>

                <div className="address-horizontal-section">
                    <div className="address-form-field">
                        <TextField name="firstName" required fullWidth label="First Name" onChange={this.onTextChange} />
                    </div>
                    <div className="address-form-field">
                        <TextField name="lastName" required fullWidth label="Last Name" onChange={this.onTextChange} />
                    </div>
                </div>

                <div className="address-horizontal-section">
                    <div className="address-form-field">
                        <TextField name="address1" required fullWidth label="Address" onChange={this.onTextChange} />
                    </div>
                </div>

                <div className="address-horizontal-section">
                    <div className="address-form-field">
                        <TextField name="address2" fullWidth label="Unit Number" onChange={this.onTextChange} />
                    </div>
                </div>

                <div className="address-horizontal-section">
                    <div className="address-form-field">
                        <TextField name="city" required fullWidth label="City" onChange={this.onTextChange} />
                    </div>
                    <div className="address-form-field">
                        <TextField name="province" value="Ontario" disabled required fullWidth label="Province" onChange={this.onTextChange}>Ontario</TextField>
                    </div>
                    <div className="address-form-field">
                        <TextField name="postalCode" inputProps={{ maxLength: 7 }} required fullWidth label="Postal Code" onChange={this.onTextChange} />
                    </div>
                </div>

                <div className="address-continue-button-container">
                    <Button className="address-continue-button" disabled={!this.areFieldsValid()} type="submit" variant="contained" onClick={this.onSubmit}>Continue to payment</Button>
                </div>
            </FormControl>
        </div>
    }

}