interface TicketLineItem {
    quantity: number,
    lineTotal: number
}

interface TicketBreakdown {
    onePackLine: TicketLineItem | null,
    threePackLine: TicketLineItem | null,
    eightPackLine: TicketLineItem | null,
}

const ONE_PACK_PRICE_CENTS = 500
const THREE_PACK_PRICE_CENTS = 1000
const EIGHT_PACK_PRICE_CENTS = 2500

export class TicketCalculator {

    // TODO: Build line item objects and include both quantity and total cost for each line.
    calculate(quantity: number): TicketBreakdown {
        let remainingQuantity = quantity
        let onePacks = 0
        let threePacks = 0
        let eightPacks = 0

        while (remainingQuantity >= 8) {
            eightPacks++
            remainingQuantity -= 8
        }

        while (remainingQuantity >= 3) {
            threePacks++
            remainingQuantity -= 3
        }

        while (remainingQuantity >= 1) {
            onePacks++
            remainingQuantity -= 1
        }

        const eightPackLineItem = eightPacks > 0 ? {
            quantity: eightPacks,
            lineTotal: eightPacks * EIGHT_PACK_PRICE_CENTS
        } : null

        const threePackLineItem = threePacks > 0 ? {
            quantity: threePacks,
            lineTotal: threePacks * THREE_PACK_PRICE_CENTS
        } : null

        const onePackLineItem = onePacks > 0 ? {
            quantity: onePacks,
            lineTotal: onePacks * ONE_PACK_PRICE_CENTS
        } : null

        return {
            eightPackLine: eightPackLineItem,
            threePackLine: threePackLineItem,
            onePackLine: onePackLineItem
        }
    }


}


export const ticketCalculator: TicketCalculator = new TicketCalculator();