import React from "react";
import "./PrizePage.css"
import {TermsContent} from "./TermsContent";

export class TermsPage extends React.Component<any, any> {
    render() {
        return <div className="terms-page">
            <h1>Raffle Rules 2023</h1>
            <TermsContent />
        </div>
    }
}