// @ts-nocheck
// Not checking because we have to import paysafe from HTML.


import {userDataService} from "../address/UserDataService";
import { v4 as uuid } from 'uuid';
import {ticketService} from "../../../network/TicketService";

const PAYSAFE_USERNAME = process.env.REACT_APP_PAYSAFE_USERNAME
const PAYSAFE_PASSWORD = process.env.REACT_APP_PAYSAFE_PASSWORD
const PAYSAFE_ENVRIONMENT = process.env.REACT_APP_PAYSAFE_ENVIRONMENT

class PaysafeService {

    instance = undefined


    init() {
        const options = {
            // You must provide currencyCode to the Paysafe JS SDK to enable the Payment API integration
            currencyCode: "CAD",
                // select the Paysafe test / sandbox environment
            environment: PAYSAFE_ENVRIONMENT,
            // Provide a cards merchant toaccount if you have more than one configured for that same API key
            // accounts: {
            //   default: 1002602200,
            // },
            // set the CSS selectors to identify the payment field divs above
            // set the placeholder text to display in these fields
            fields: {
                cardNumber: {
                    selector: '#cardNumber',
                        placeholder: 'Card number',
                        separator: ' ',
                },
                // cardholderName: {
                //     selector: '#cardholderName',
                //         placeholder: 'Cardholder name'
                // },
                expiryDate: {
                    selector: '#expiryDate',
                        placeholder: 'Expiry date',
                },
                cvv: {
                    selector: '#cvv',
                        placeholder: 'CVV',
                        // optional: false,
                },
            },
        };

        const paysafe = window.paysafe

        const API_KEY = window.btoa(`${PAYSAFE_USERNAME}:${PAYSAFE_PASSWORD}`)
        console.log(API_KEY)
        paysafe.fields.setup(API_KEY, options)
            .then((instance, error) => {
                this.instance = instance




            })
    }

    getToken(): Promise<string> {
        const userData = userDataService.getUserData()
        if (userData == null) {
            return Promise.reject()
        }

        ticketService.getCartId()
        const merchantRefNum = uuid().replaceAll("-", "")

        return ticketService.fetchCart()
            .then(cart => {
                const tokenOptions = {
                    amount: cart.lineItems.totalInCents,
                    transactionType: 'PAYMENT',
                    paymentType: 'CARD',
                    merchantRefNum: merchantRefNum, // TODO: Pass this to backend
                    customerDetails: {
                        billingDetails: {
                            street: userData.address1,
                            city: userData.city,
                            state: userData.province,
                            zip: userData.postalCode,
                            country: userData.country,
                        },
                    },
                }

                return this.instance.tokenize(tokenOptions)
                    .then(result => {
                        return result.token
                    })
            })


    }
}

export const paysafeService: PaysafeService = new PaysafeService();