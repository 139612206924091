import React from "react";
import {orderService} from "../../../network/OrderService";
import {Address} from "../../../network/Address";
import {userDataService} from "../address/UserDataService";
import "./OrderPage.css"
import {OrderLineItemModel, OrderModel} from "../../../network/OrderNetwork";
import {Divider, Paper} from "@mui/material";
import {LineItem} from "./LineItem";

interface OrderCompleteProps {
    order: OrderModel
}
interface OrderCompleteState {}

export class OrderCompleteContent extends React.Component<OrderCompleteProps, OrderCompleteState> {

    currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
    });

    dividerStyle = {
        color: '#888888',
        width: 1.0,
    }

    paperStyle = {
        bgcolor: '#FDFDFD'
    }

    renderTicketNumbers(): React.ReactElement {
        const ticketNumbers = this.props.order.raffleTicketNumbers
        let ticketDivs = ticketNumbers.map((ticketNumber, index) => {
            const ticketId = `ticket-${ticketNumber}`
            const comma = ticketNumbers.length === index + 1 ? null : ", "
            return <span key={ticketId} id={ticketId}>{ticketNumber}{comma}</span>
        })

        return <div className="ticket-list">
            <Divider sx={this.dividerStyle} />
            <h3 className="order-ticket-numbers-header">Ticket Numbers</h3>
            {ticketDivs}
            <div className="ticket-email-message">Tickets will be emailed after ticket sales close.</div>
        </div>
    }

    renderBillingAddress() {
        if (!this.props.order.billingAddress) {
            return null
        }
        const billingAddress = this.props.order.billingAddress as Address

        const userData = userDataService.getUserData()
        if (userData == null) {
            return null
        }

        return <Paper elevation={3} sx={this.paperStyle} className="order-customer-information">
            <h2>Customer Information</h2>
            <div className="order-billing-address">
                <div>{userData.firstName}&nbsp;{userData.lastName}</div>
                <div>{billingAddress.line1}</div>
                <div>{billingAddress.line2}</div>
                <div>{billingAddress.city},&nbsp;{billingAddress.province},&nbsp;{billingAddress.postalCode}</div>
            </div>
        </Paper>
    }

    renderOrderSummary(): React.ReactElement | null {
        console.log(this.props.order.lineItems)
        if (!this.props.order.lineItems) {
            return null
        }
        const lineItems = this.props.order.lineItems

        const formattedTotal = this.currencyFormatter.format(lineItems.totalInCents / 100)

        return <Paper elevation={3} sx={this.paperStyle} className="order-summary">
            <h2>Order Summary</h2>
            <div className="order-id">Order ID: {this.props.order.id}</div>
            {this.renderLineItem(8, lineItems.eightPacks, lineItems.eightPackTotalCents)}
            {this.renderLineItem(3, lineItems.threePacks, lineItems.threePackTotalCents)}
            {this.renderLineItem(1, lineItems.onePacks, lineItems.onePackTotalCents)}
            <div className="order-total-line-item-wrapper">
                <Divider sx={this.dividerStyle} />
                <div className="order-total-line-item-container">
                    <div className="order-total-text">Total:</div>
                    <div className="order-total-value">{formattedTotal}</div>
                </div>
            </div>

            {this.renderTicketNumbers()}
        </Paper>
    }

    renderLineItem(packType: number, quantity: number, price: number): React.ReactElement | null {
        if (quantity === 0) {
            return null
        }

        return <LineItem packType={packType} quantity={quantity} price={price} />
    }

    render() {
        return (
            <div className="order-content">
                <div className="order-horizontal-section">
                    {this.renderOrderSummary()}
                    {this.renderBillingAddress()}
                </div>
            </div>
        );
    }
}