
interface UserData {
    firstName: string,
    lastName: string,
    email: string,
    phone: string,
    address1: string,
    address2: string | null,
    city: string,
    province: string,
    postalCode: string,
    country: string,
}
const USER_BILLING_DATA_KEY = "userBillingData"
const CART_ID_KEY = "cartId"

class UserDataService {

    saveUserBilling(params: UserData) {
        localStorage.setItem(USER_BILLING_DATA_KEY, JSON.stringify(params))
    }

    getUserData(): UserData | null {
        const userDataString = localStorage.getItem(USER_BILLING_DATA_KEY)
        if (userDataString) {
            return JSON.parse(userDataString)
        }

        return null
    }

    getCartId(): string | null {
        return localStorage.getItem(CART_ID_KEY)
    }

    saveCartId(cartId: string): string {
        localStorage.setItem(CART_ID_KEY, cartId)

        return cartId
    }

}

export const userDataService: UserDataService = new UserDataService();