import {AppBar, Button, Divider, Drawer, IconButton, MenuItem, Toolbar, Typography} from "@mui/material";
import React from "react";
import "./App.css"
import iceFestLogo from "/SOIceFest_Logo.png"
import {AppMenu} from "./ticket/AppMenu";

interface AppMenuProps {
    children: React.ReactNode
}
interface AppMenuState {}


const AppHeader = (props: AppMenuProps) => {

    const dividerStyle = {
        color: '#888888',
        width: 1.0,
        m: 1
    }

    return(
         <div className="app-header">
             <img src="/SOIceFest_Logo.png" className="app-header-image" />
             <Divider sx={dividerStyle} />
             <AppMenu />
             <Divider sx={dividerStyle} />
             <div className="content-container">
                 {props.children}
             </div>
        </div>
    )
}

export default AppHeader;