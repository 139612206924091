import {userDataService} from "../component/app/address/UserDataService";
import axios from "axios";
import {OrderCreateRequest, OrderModel, OrderResponse} from "./OrderNetwork";

const ORDER_BASE_PATH = "/order"
const ORDER_GET_PATH = "/"
const ORDER_CREATE_PATH = "/create"

const ORDER_BASE_URL = process.env.REACT_APP_TICKET_SERVER_BASE_URL + ORDER_BASE_PATH

const ORDER_ID_KEY = "orderId"

export class OrderService {

    createOrder(paymentToken: string) : Promise<OrderModel> {
        const url = ORDER_BASE_URL + ORDER_CREATE_PATH
        const cartId = userDataService.getCartId()
        const data = {
            cartId: cartId,
            paymentToken: paymentToken
        }

        return axios.post<OrderCreateRequest, OrderResponse>(url, data)
            .then(response => {
                const order = response.data.data
                localStorage.setItem(ORDER_ID_KEY, order.id)

                return response.data.data
            })
    }

    getOrder(): Promise<OrderModel> {
        const orderId = localStorage.getItem(ORDER_ID_KEY)
        if (orderId == null) {
            return Promise.reject()
        }

        const url = ORDER_BASE_URL + ORDER_GET_PATH + orderId
        return axios.get(url)
            .then(response => {
                return response.data.data
            })
    }
}
export const orderService: OrderService = new OrderService();