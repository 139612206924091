import React from "react";
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import "./PrizePage.css"

export class PrizeGrid extends React.Component<any, any> {

    PRIZE_LIST = [
        {prizeId: 1, description: <div><a href="https://arcteryx.com/ca/en/">Arc'teryx</a>: Full Outfit (Jacket, mid-layer, and pants)</div>, retailValueDollars: 1750, winner: "Daniel Leather, Hamilton, #0486"},
        {prizeId: 2, description: <div><a href="https://arcteryx.com/ca/en/">Arc'teryx</a>: Full Outfit (Jacket, mid-layer, and pants)</div>, retailValueDollars: 1750, winner: "Richard Allen, Stittsville, #0521"},
        {prizeId: 3, description: <div>Ticket to Paddler Coop <a href="https://paddlerco-op.ca/courses/palmer-river-fest/">Palmer River Fest</a> Spring or Fall</div>, retailValueDollars: 400, winner: "Mike Sam, Bradford, #0440"},
        {prizeId: 4, description: <div>2-hour <a href="https://www.mkc.ca/">Madawaska Kanu Centre</a> Adventure Raft Trip on the Madawaska River for 1 adult</div>, retailValueDollars: 95, winner: "Richard Allen, Stittsville, #0503"},
        {prizeId: 5, description: <div>Petzl Speed Light Ice Screw</div>, retailValueDollars: 110, winner: "Karen Choy, Ottawa, #0234"},
        {prizeId: 6, description: <div><a href="https://wildrock.net/">Wild Rock Outfitters</a> (Peterborough) Gift Certificate and 'The Arlington' Gift Certificate</div>, retailValueDollars: 100, winner: "Richard Allen, Stittsville, #0513"},
        {prizeId: 7, description: <div><a href="https://www.ontarioresoles.ca/">Ontario Resoles</a> Gift Certificate and Southern Ontario Ice: A Select Guide to Ice and Mixed Climbing' Guidebook</div>, retailValueDollars: 102, winner: "Tony Que, Toronto, #0134"},
        {prizeId: 8, description: <div><a href="https://rockandchalk.com/">Of Rock and Chalk</a> (Newmarket) 2 month Gym Membership</div>, retailValueDollars: 130, winner: "Marc Cerulli, Mississauga, #0351"},
        {prizeId: 9, description: <div><a href="https://mountaineer.com/">The Mountaineer</a> (Keene Valley, NY) Gift Certificate</div>, retailValueDollars: 100, winner: "Brooklin Bamlett, Barrie, #0305"},
        {prizeId: 10, description: <div><a href="https://rockandchalk.com/">Of Rock and Chalk</a> (Newmarket) 2 month Gym Membership</div>, retailValueDollars: 130, winner: "Richard Allen, Stittsville, #0510"}
    ]

    currencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'CAD',
    });

    render() {
        return <div className="prize-grid">
            <h4>SOIceFest 8 Raffle Prize List (February 11, 2023 @8pm; ANAF Hall - Maynooth)</h4>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell className="prize-grid-number-column">Prize #</TableCell>
                            <TableCell>Description of Prize Pack</TableCell>
                            <TableCell>Approximate Retail Value</TableCell>
                            <TableCell>Winner's Name and Town/City</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.PRIZE_LIST.map(prize =>
                                <TableRow key={"prize-" + prize.prizeId}>
                                    <TableCell align="left">{prize.prizeId}</TableCell>
                                    <TableCell align="left">{prize.description}</TableCell>
                                    <TableCell align="right">{this.currencyFormatter.format(prize.retailValueDollars)}</TableCell>
                                    <TableCell align="left">{prize.winner}</TableCell>
                                </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    }
}