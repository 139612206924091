import React from "react";
import TextField from "@mui/material/TextField";
import {TicketLineItems} from "./TicketLineItems";
import "./TicketForm.css"
import {Button} from "@mui/material";
import {userDataService} from "../address/UserDataService";
import {Navigate} from "react-router-dom";
import {gymService} from "../../../network/GymService";
import {ticketService} from "../../../network/TicketService";

interface TicketFormProps {}
interface TicketFormState {
    quantity: number,
    isSubmitted: boolean

}

export class TicketForm extends React.Component<TicketFormProps, TicketFormState> {

    state = {
        quantity: 1,
        isSubmitted: false
    }

    onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e.currentTarget.value)
        this.setState({
            quantity: +e.currentTarget.value
        })
    }

    addToCart = () => {
        const cartId = userDataService.getCartId()

        console.log(cartId)

        if (cartId == null) {
            console.log("creating")
            ticketService.createCart(this.state.quantity)
                .then(cart => {
                    userDataService.saveCartId(cart.id)
                    this.setState({
                        isSubmitted: true
                    })
                })
        } else {
            console.log("updating")
            ticketService.updateCart(this.state.quantity)
                .then(cart => {
                    console.log(cart)
                    this.setState({
                        isSubmitted: true
                    })
                })
        }


    }

    render() {
        return <div className="ticket-form-wrapper">
            <div className="ticket-form">
                {this.state.isSubmitted ? <Navigate to="/contact" /> : null }
                <div>
                    <TextField type="number" label="Quantity" value={this.state.quantity} onChange={this.onQuantityChange} InputProps={{ inputProps: { min: 1 }}}>1</TextField>
                    <TicketLineItems quantity={this.state.quantity} />
                </div>
                <Button id="addToCart" variant="contained" disabled={this.state.quantity < 1} onClick={this.addToCart}>Add To Cart</Button>
            </div>
        </div>;
    }

}