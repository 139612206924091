import React from "react";
import {TicketForm} from "./TicketForm";
import "./TicketForm.css"
import {TicketPriceChart} from "./TicketPriceChart";

export class TicketPage extends React.Component {

    render() {
        return <div className="ticket-page">
            <h1>Tickets</h1>
            <h4>For optimal viewing experience, please use a computer.</h4>
            <div className="ticket-page-horizontal">
                <TicketForm />
                <TicketPriceChart />
            </div>
        </div>
    }
}
